import request from '@/utils/request'

// 查询穿衣任务
export function findDressTasks(params) {
  return request({
    url: `/tasks/dress`,
    method: 'get',
    params: params
  })
}

export function findDressTask(id) {
  return request({
    url: `/tasks/${id}/dress`,
    method: 'get'
  })
}

// 更新穿衣任务状态
export function updateDressTaskStatus(id, data) {
  return request({
    url: `/tasks/${id}/update_dress_status`,
    method: 'post',
    data: data
  })
}

// 查询货品配送任务
export function findAssortingTasks(params) {
  return request({
    url: `/tasks/assorting`,
    method: 'get',
    params: params
  })
}

// 更新穿衣任务状态
export function finishAssortTask(id, data) {
  return request({
    url: `/tasks/${id}/finish_assort`,
    method: 'post',
    data: data
  })
}

