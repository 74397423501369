<template>
  <div class="custom-height-100p custom-flex custom-flex-col">
    <search @submit="submitSearch" />
    <a-row :gutter="24" class="custom-height-100p custom-flex-1" style="overflow: hidden;" v-if="data && data.length > 0">
      <a-col span="8" v-for="task in data" :key="task.id" class="custom-height-100p" style="overflow: auto;">
        <div class="custom-card-box">
          <!--标题-->
          <div class="task-title-box custom-flex custom-justify-between">
            <a-space>
              <span class="title">货品配货</span>
              <div class="status-box">{{ task.status }}</div>
            </a-space>
            <a-button
              icon="check-circle"
              style="background-color: #ffd794ff;"
              size="large"
              v-if="task.status_slug === 'assorting_processing'"
              @click="showInputEmpNoModal(task.id)"
            >
              完成配货
            </a-button>
          </div>

          <a-divider />

          <a-descriptions
            :column="1"
            class="custom-descriptions custom-descriptions-max-6"
          >
            <a-descriptions-item label="任务创建时间">{{ task.created_at }}</a-descriptions-item>
            <a-descriptions-item label="关联订单">
              <span class="custom-word-break-all">{{ task.order_no }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="任务负责人">{{ task.task_user_name }} / {{ task.task_user_phone_number }}</a-descriptions-item>
            <a-descriptions-item label="订单负责人">{{ task.order_user_name }} / {{ task.order_user_phone_number }}</a-descriptions-item>
            <a-descriptions-item label="订单申请人">{{ task.applicant_name }} / {{ task.applicant_phone_number }}</a-descriptions-item>
          </a-descriptions>

          <a-divider />

          <a-descriptions
            :column="2"
            class="custom-descriptions"
          >
            <a-descriptions-item label="收货地点">{{ task.address }}</a-descriptions-item>
            <a-descriptions-item label="备注">{{ task.remark }}</a-descriptions-item>
          </a-descriptions>

          <a-divider />
          <span class="goods-title">货品清单:</span>
          <!--货品清单-->
          <a-table
            :columns="columns"
            :data-source="task.goods"
            :loading="loadingGoods"
            :pagination="false"
            :bordered="true"
            style="margin-top: 10px;"
            row-key="id"
          >
            <span slot="spec_name" slot-scope="text">
              <span style="color: #f59a23;">{{ text }}</span>
            </span>
            <span slot="actions" slot-scope="text, record">
              <a
                class="custom-blue"
                v-if="record.more_info && record.more_info.length > 0"
                @click="showElegiacCoupletModal(record.more_info)"
              >
                挽联信息
              </a>
            </span>
          </a-table>
        </div>
      </a-col>
    </a-row>
    <no-data class="custom-card-box" v-else />
    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <elegiac-couplet
      v-if="isShowElegiacCoupletModal"
      :visible.sync="isShowElegiacCoupletModal"
      :data="elegiacCouplets"
    />

    <InputEmpNo
      title="完成配货"
      v-if="isShowInputEmpNoModal"
      :visible.sync="isShowInputEmpNoModal"
      @completed="handleOperate"
    />
  </div>
</template>

<script>
import { findAssortingTasks, finishAssortTask } from '@/api/task'
import Search from '@/views/home/delivery_screen/Search'
import Pagination from '@/components/Pagination'
import NoData from '@/views/home/NoData'
import SpeakTts from 'speak-tts'
export default {
  name: 'DeliveryScreenHome',
  components: {
    Search,
    Pagination,
    NoData,
    ElegiacCouplet: () => import('@/views/home/delivery_screen/ElegiacCouplet'),
    InputEmpNo: () => import('@/views/home/InputEmpNo')
  },
  data() {
    return {
      data: [],
      isShowInputEmpNoModal: false,
      isShowElegiacCoupletModal: false,
      elegiacCouplets: [],
      operateTaskId: 0,
      loadingGoods: true,
      query: {
        per_page: 3
      },
      pagination: {
        total_count: 0
      },
      tts: null,
      audio: null
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '货品名称',
          dataIndex: 'name'
        },
        {
          title: '规格',
          dataIndex: 'spec_name',
          scopedSlots: { customRender: 'spec_name' }
        },
        {
          title: '数量',
          dataIndex: 'count'
        },
        {
          title: '操作',
          width: 150,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  created() {
    this.fetchData()
    this.loopFetchData()
  },
  destroyed() {
    this.$socket.unsubscribe('delivery_screen_messages', this.unsubscribe)
    clearInterval(this.loopFetchDataTimer)
  },
  mounted() {
    // 初始化audio
    this.audio = new Audio()
    // 初始化
    this.tts = new SpeakTts()
    this.tts.setLanguage('zh-CN')
    this.tts.init({
      rate: 1
    }).then(() => {})
    this.$socket.subscribe('delivery_screen_messages', this.play)
  },

  methods: {
    speak(res) {
      console.log(res)
      const text = '有新的配货任务来了，请尽快处理'
      this.tts.setLanguage('zh-CN')
      // this.tts.setVoice('婷婷')
      // this.tts.setVoice('Microsoft Kangkang - Chinese (Simplified, PRC)')
      this.tts.speak({
        text: text,
        queue: true,
        listeners: {
          onend: () => {
            console.log('finished speaking')
          }
        }
      })
      this.fetchData()
    },
    play(res) {
      this.audio.src = window.customConfig.order_prompt_sound // 设置audio的src为上面生成的url
      this.audio.play()// 进行播放
      this.fetchData()
    },
    unsubscribe() {
      console.log('unsubscribe')
    },
    loopFetchData() {
      this.loopFetchDataTimer = setInterval(this.fetchData, 30000)
    },

    fetchData() {
      this.loadingGoods = true
      findAssortingTasks(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
      })
      this.loadingGoods = false
    },

    showInputEmpNoModal(operateTaskId) {
      this.operateTaskId = operateTaskId
      this.isShowInputEmpNoModal = true
    },

    showElegiacCoupletModal(elegiacCouplets) {
      this.isShowElegiacCoupletModal = true
      this.elegiacCouplets = elegiacCouplets
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    handleOperate(value) {
      finishAssortTask(this.operateTaskId, value).then((res) => {
        if (res.code === 0) {
          this.fetchData()
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.status-box {
  padding: 0 5px;
  border-radius: 100px;
  color: #333;
  background-color: #d8eeeb;
}

.task-title-box {
  .title {
    font-size: 24px;
  }
}

.goods-title {
  color: #f59a23;
}
</style>
